$primary: #356E81;
$secondary: #B55C2C;
$success: #532565;
$info: #bf362e;
$warning:  #e07c3e;
$danger: #981f32;
$light: #dddddd;
$dark: #373a3c;

$font-family-sans-serif: 'Raleway', sans-serif;
$link-color: $secondary;

@import '~bootstrap/scss/bootstrap.scss';

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px white, 0 0 0 6px black !important;
}

@media (min-width: 992px) {
  .header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .app-container {
    padding-top: 72px;
  }
}

body {
  color: $dark;
}

h1, h2, h3, h4 {
  color: $primary;
  font-weight: 700 !important;
}

.header-cap {
  background-color: $white;
  .graphicElementGradient {
    background-image: linear-gradient(
        to right,
        #356e81 10%,
        #856ca2 50%,
        #d17442 100%
    );
  }
}

.header {
  font-size: 1.25rem;
  .navbar-nav .nav-link.active {
    color: $secondary;
    text-decoration: underline;
  }
}

.resizing-container {
  padding: 0;
}

.login-panel.login-panel {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  border-radius: 0.8rem;
  margin-top: 0.75rem;
  margin-bottom: 2.5rem;
  padding: 0;
  .login-col-img {
    img {
      border-radius: 0.8rem 0.8rem 0 0;
      min-height: 100%;
      max-height: 320px;
      min-width: 100%;
      max-width: 100%;
    }
    @media (min-width: 768px) {
      order: 2;
      img {
        border-radius: 0.8rem;
      }
    }
  }
  .login-logo-img {
    max-height: 140px;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.title-toolbar.title-toolbar {
  margin-bottom: 2.5rem;
  .col-navbar {
    .navbar {
      background-color: $primary;
      border-radius: 0.8rem;
      .btn:not(.dropdown-item) {
        color: $white;
        padding: 0 8px;
      }
      .navbar-text {
        padding: 0;
      }
    }
  }
}

.story-grid.story-grid {
  &:hover {
    box-shadow: $primary 0 0 15px;
  }
  border-radius: 0.8rem;
  .rdw-editor-main {
    height: 460px;
    overflow-y: auto;
    .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
      height: unset;
    }
  }
  .btn.btn-outline-secondary {
    border-color: $light;
  }
}

.story-panel.story-panel {
  border-radius: 0.8rem;
  position: relative;
  .btn-link {
    color: $secondary;
    text-decoration: underline;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .slider-content {
    .files {
      max-height: calc(100vh - 160px);
      overflow-y: auto;
    }
  }
}

.rdw-link-decorator-wrapper {
  a {
    color: $secondary;
    text-decoration: underline;
  }
  .rdw-link-decorator-icon {
    display: none;
  }
}

.document-panel.document-panel {
  border-radius: 0.8rem;
  .action-bar {
    background-color: white;
    position: sticky;
    top: 72px;
  }
}

.rendered-file-browser.rendered-file-browser {
  .files table {
    margin-bottom: 0;
  }
  a, .item-actions label {
    cursor: pointer;
    color: $dark;
  }
}

.applicant-table {
  thead {
    position: sticky;
    top: 72px;
    z-index: 1000;
  }
}

.footer {
  color: $gray-600;
  img {
    max-width: 300px;
  }
}

